import React, { memo, useMemo } from "react";
import Creatable from "react-select/creatable";

export const CustomLookup = ({
  className,
  placeholder,
  field,
  form,
  options,
  isMulti = false,
  disabled,
  formType,
  setValues,
  values,
  fulldata,
  edit,
  arrIndex,
  labelType
}) => {
  const onChange = (option) => {
    // setValues({ ...values, [field?.name]: option?.value });
    // console.log("fulldata==bbb=qqqqq> arrIndex Invoices33 4-->", fulldata, values, option, field, formType);


    if (values?.contactTitle == "Leads" && field.name == "ContactName") {
      console.log("fulldata==bbb=qqqqq> arrInkjkhkjdex Invoices", labelType, values, option, field, formType);
      setValues({
        ...values,
        "RelatedTo": option?.label,
        "connectionId": option?.value,
        "ContactName": option?.label ? option?.label : values.ContactName


      });
    }
    else if (field?.name == "RelatedTo") {
      console.log("fulldata==bbb=qqqqq> arrIndex Invoices33", labelType, values, option, field, formType);

      setValues({
        ...values,
        // "ContactName": option?.label ? option?.label : values.ContactName,
        "RelatedTo": option?.label,
        "connectionId": option?.value,
      });
    }
    else if (values?.orderedItems && field?.name == "InventoryName") {
      console.log("===option=>orderedItems", values, option, field, values);

      setValues({
        ...values,
        orderedItems: [...values?.orderedItems,
        values.orderedItems[arrIndex][field.name] = option?.value]
      });

    }
    else if ((formType === "Opportunities" ||
      formType === "Opportunity" ||
      formType === "deals")
      &&
      (field?.name === "ContactName"
        // || field?.name === "AccountName"
      )
    ) {
      // let accoutId = fulldata?.find((it) => it?._id === option?.value);
      let accoutId = fulldata?.find((it) =>
        it?.value === option?.value || it?.label === option?.value || it?._id === option?.value
      )
      console.log("fulldata==bbb=qqqqq> arrIndex Invoices33 4-->", fulldata, accoutId, option);

      setValues({
        ...values,
        AccountName: edit ? accoutId?._id : accoutId?.AccountName,
        ContactName: option?.value,
      });
      // return
    }
    else {
      let newkey = field?.name == "AccountName" ? option?.value : null
      setValues({
        ...values,
        [field?.name]: field?.name == "AccountName" ? option?.label : option?.value,
        ["AccountsOwnerId"]: newkey ? newkey : values?.AccountsOwnerId,
        // "connectionId": values?.RelatedTo

      });
    }
    console.log("fulldata==bbb=else qqqqq> arrIndex Invoices33 4-->", values, option);

  }
  const isHexadecimal = (s) => {
    try {
      // Attempt to parse the string as an integer in base 16 (hexadecimal)
      let value = (s.length == 24 && parseInt(s, 16))
      if (value) {
        return ""; // If successful, the string is hexadecimal

      } else {
        return s;
      }
    } catch (e) {
      return false; // If an exception is caught, the string is not hexadecimal
    }
  }
  const getValue = useMemo(() => {

    // console.log("field=65432145sldkfdfdsj63=>", values, options, field, formType);


    if (options) {
      console.log("lookupDataTypelookupDataType1", options, values, field);
      let valueis = isMulti
        ? options?.filter((option) => field?.value?.indexOf(option.value) >= 0)
        : options?.find((option) => (option?.value === field?.value || option?.label === field?.value));


      // let valueis = isMulti
      //   ? options?.filter((option) => field?.value?.indexOf(option.value) >= 0)
      //   : options?.find((option) =>
      //     option?.value === field?.value
      //       ? option?.label
      //       : option?.label === field?.value
      //         ? option.label
      //         : ""
      //   );
      // option?.value === values?._id ? option.label
      console.log("hghfsghsdg", valueis);
      if (!valueis || valueis == undefined) {
        valueis = isMulti
          ? options?.filter((option) => field?.value?.indexOf(option.value) >= 0)
          : options?.find((option) => (option?.value === values?._id));

        if (valueis) {
          return valueis ? valueis : { label: field.label, value: field.value };

        }
        else {
          console.log("valueisvalueis", values, field)
          if (field.name == "RelatedTo") {
            return { label: isHexadecimal(values?.RelatedTo), value: isHexadecimal(values?.RelatedTo) };
          }
          if (field.name == "ContactName") {
            return { label: isHexadecimal(values?.ContactName), value: isHexadecimal(values?.ContactName) };
          }
          if (field.name == "ChannelPartnerName") {
            return { label: (!values?.ChannelPartnerName || values?.ChannelPartnerName == null) ? "" : values?.ChannelPartnerName, value: (!values?.ChannelPartnerName || values?.ChannelPartnerName == null) ? "" : values?.ChannelPartnerName };
          }
          if (field.name == "AccountName" || field.name == "Company" || field.name == "CompanyName") {
            return { label: values?.AccountName || values?.Company || values?.CompanyName, value: values?.AccountName || values?.Company || values?.CompanyName };
          }
          if (field.name == "LOISubject") {
            return { label: values?.LOISubject, value: values?.LOISubject };
          }

        }
      }
      if (valueis) {
        return valueis ? valueis : { label: field.label, value: field.value };

      }

      else {
        console.log("valueisvalueis", values, field)
        if (field.name == "RelatedTo") {
          return { label: isHexadecimal(values?.RelatedTo), value: isHexadecimal(values?.RelatedTo) };
        }
        if (field.name == "ContactName") {
          return { label: isHexadecimal(values?.ContactName), value: isHexadecimal(values?.ContactName) };
        } if (field.name == "ChannelPartnerName") {
          return { label: (!values?.ChannelPartnerName || values?.ChannelPartnerName == null) ? "" : values?.ChannelPartnerName, value: (!values?.ChannelPartnerName || values?.ChannelPartnerName == null) ? "" : values?.ChannelPartnerName };
        }
        if (field.name == "AccountName" || field.name == "Company" || field.name == "CompanyName") {
          return { label: values?.AccountName || values?.Company || values?.CompanyName, value: values?.AccountName || values?.Company || values?.CompanyName };
        }
        if (field.name == "LOISubject") {
          return { label: values?.LOISubject, value: values?.LOISubject };
        }

      }

    } else {
      return isMulti ? [] : "";
    }



  }, [options, field]);

  // const getValue = useMemo(() => {

  //   console.log("field=6543214563=>", values, options, field, formType);
  //   if ((field?.name === "RelatedTo" || field?.name === "Lookup") && (field?.value == values?.Lookup || field?.value == values?.RelatedTo) && options?.length > 0) {
  //     //   console.log("field=654321=>1", options, values, field);
  //     let getStatus = options.filter((item, index) => values?.RelatedTo ? item.value === values?.RelatedTo : item.value === values?.Lookup)
  //     //  console.log("getStatus===>", options, values, getStatus);
  //     let valueis = options?.find((option) =>
  //       option?.value == values?._id
  //         ? option?.label : option?.label == values?.RelatedTo ? option?.label : ""
  //     );
  //     // console.log("valueis --->", valueis);
  //     return valueis ? valueis : { label: field.label, value: field.value };
  //   }
  //   if ((field?.name === "RelatedTo" || field?.name === "Lookup") && (field?.value == values?.Lookup || field?.value == values?.RelatedTo) && options?.length > 0) {
  //     // console.log("field=654321=>1", options, values, field);
  //     let getStatus = options.filter((item, index) => values?.RelatedTo ? item.value === values?.RelatedTo : item.value === values?.Lookup)
  //     // console.log("getStatus===>", options, values, getStatus);
  //     let valueis = options?.find((option) =>
  //       option?.value == values?._id
  //         ? option?.label : option?.label == values?.RelatedTo ? option?.label : ""
  //     );
  //     // console.log("valueis --->", valueis);
  //     return valueis ? valueis : { label: field.label, value: field.value };
  //   } else {
  //     if (values?.RelatedTo) {
  //       return { label: values?.RelatedTo, value: values?.RelatedTo };
  //     }

  //     if (options) {
  //       // console.log("lookupDataTypelookupDataType1", options, values, field);

  //       let valueis = isMulti
  //         ? options?.filter((option) => field?.value?.indexOf(option.value) >= 0)
  //         : options?.find((option) =>
  //           option?.value === field?.value
  //             ? option?.label
  //             : option?.label === field?.value
  //               ? option.label
  //               : option?.value === values?._id ? option.label : ""
  //         );
  //       // console.log("valueis", valueis);
  //       return valueis ? valueis : { label: field.label, value: field.value };
  //     } else {
  //       return isMulti ? [] : "";
  //     }


  //   }
  // }, [options, field]);

  const getArrayValue = useMemo(() => {
    console.log("field=6543214563=>", values, options, field, formType);

    console.log("field=6543214563=>", values, options, field, formType);
    if ((field?.name === "RelatedTo" || field?.name === "Lookup") && (field?.value == values?.Lookup || field?.value == values?.RelatedTo) && options?.length > 0) {
      //   console.log("field=654321=>1", options, values, field);
      let getStatus = options.filter((item, index) => values?.RelatedTo ? item.value === values?.RelatedTo : item.value === values?.Lookup)
      //  console.log("getStatus===>", options, values, getStatus);
      let valueis = options?.find((option) =>
        option?.value == values?._id
          ? option?.label : option?.label == values?.RelatedTo ? option?.label : ""
      );
      // console.log("valueis --->", valueis);
      return valueis ? valueis : { label: field.label, value: field.value };
    }
    if ((field?.name === "RelatedTo" || field?.name === "Lookup") && (field?.value == values?.Lookup || field?.value == values?.RelatedTo) && options?.length > 0) {
      // console.log("field=654321=>1", options, values, field);
      let getStatus = options.filter((item, index) => values?.RelatedTo ? item.value === values?.RelatedTo : item.value === values?.Lookup)
      // console.log("getStatus===>", options, values, getStatus);
      let valueis = options?.find((option) =>
        option?.value == values?._id
          ? option?.label : option?.label == values?.RelatedTo ? option?.label : ""
      );
      // console.log("valueis --->", valueis);
      return valueis ? valueis : { label: field.label, value: field.value };
    } else {
      if (values?.RelatedTo) {
        return { label: values?.RelatedTo, value: values?.RelatedTo };
      }

      if (options) {
        // console.log("lookupDataTypelookupDataType1", options, values, field);

        let valueis = isMulti
          ? options?.filter((option) => field?.value?.indexOf(option.value) >= 0)
          : options?.find((option) =>
            option?.value === field?.value
              ? option?.label
              : option?.label === field?.value
                ? option.label
                : option?.value === values?._id ? option.label : ""
          );
        // console.log("valueis", valueis);
        return valueis ? valueis : { label: field.label, value: field.value };
      } else {
        return isMulti ? [] : "";
      }
    }
  }, [options, field]);

  return (
    <>
      {console.log("getValue=getValue==>", getValue, values, getArrayValue)}
      {values?.orderedItems ? <Creatable
        className={className}
        name={field.name}
        // value={getArrayValue}
        onChange={onChange}
        placeholder={placeholder}
        options={options}
        isMulti={isMulti}
        isDisabled={disabled}
        noOptionsMessage={() => "Quick Create "}
      />
        :
        !values?.connectionId ? <Creatable
          className={className}
          name={field.name}
          // value={field.name == "RelatedTo" ? "" : getValue}
          value={getValue}
          onChange={onChange}
          placeholder={placeholder}
          options={options}
          isMulti={isMulti}
          isDisabled={disabled}
          noOptionsMessage={() => "Quick Create "}
        />
          :
          <Creatable
            className={className}
            name={field.name}
            // value={field.name == "RelatedTo" ? "" : getValue}
            value={edit ? getValue : getValue}
            onChange={onChange}
            placeholder={placeholder}
            options={options}
            isMulti={isMulti}
            isDisabled={disabled}
            noOptionsMessage={() => "Quick Create "}
          />
      }
    </>
  );
};

export default memo(CustomLookup);
